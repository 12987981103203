import React, {useEffect, useMemo, useState} from 'react';
import Chart from 'react-apexcharts';
import {HEAT_MAP_OPTIONS} from './config';
import {Select} from '@trustle/component-library';
import {CustomLegend} from './internal/CustomLegend';
import './ApexHeatmap.css';
import {useLicenseHeatmap} from '../../hooks/useLicenseHeatmap';
import {HeatmapSeriesType, ViewType} from '../../types';
import NoData from '../../../../components/NoData';

const OPTIONS: {value: HeatmapSeriesType; label: string}[] = [
  {
    value: 'department',
    label: 'Departments',
  },
  {
    value: 'remote_role',
    label: 'Roles',
  },
  {
    value: 'team_name',
    label: 'Teams',
  },
  {
    value: 'title',
    label: 'Titles',
  },
];

type Props = {
  view: ViewType;
  licenses: string[];
  resourceId: string;
  setHeatmapLoading: (value: boolean) => void;
  showLicensesPanel: boolean;
};

const AVERAGE_LABEL = 'Average';

const refreshChart = async (series: ApexAxisChartSeries | ApexNonAxisChartSeries) => {
  const chartInstance = ApexCharts.getChartByID('responsive-heatmap');
  if (chartInstance) {
    void chartInstance.updateSeries(series, true);
  }
};

export function LicenseUsageHeatMap({view, licenses, resourceId, setHeatmapLoading, showLicensesPanel}: Props) {
  const [selectedYValue, setSelectedYValue] = useState<{value: HeatmapSeriesType; label: string}>({
    value: 'team_name',
    label: 'Teams',
  });
  const [selectedXValue, setSelectedXValue] = useState<{value: HeatmapSeriesType; label: string}>({
    value: 'remote_role',
    label: 'Roles',
  });

  const {data: heatmapData, fetchLicenseUsageHeatmap, loading} = useLicenseHeatmap({resourceId});
  useEffect(() => {
    void fetchLicenseUsageHeatmap({
      view,
      licenses,
      x_attribute: selectedXValue.value,
      y_attribute: selectedYValue.value,
    });
  }, [licenses, view, selectedXValue, selectedYValue]);

  useEffect(() => {
    setHeatmapLoading(loading);
  }, [loading]);

  const series = useMemo(() => {
    if (!heatmapData || !heatmapData.data || !heatmapData.data.length) {
      return [];
    }
    const {heatmap_rows, heatmap_columns, data, heatmap_column_means, heatmap_row_means} = heatmapData;

    // Calculates all series for the heatmap
    const series = heatmap_rows.map((rowName, rowIndex) => {
      return {
        name: rowName || 'Unknown',
        data: heatmap_columns.map((colName, colIndex) => {
          return {
            x: colName || 'Unknown',
            y: Math.round(data[rowIndex][colIndex] * 100),
          };
        }),
      };
    });

    // Calculates for column average (Adds new row average)
    const columnMeanSeries = [
      {
        name: AVERAGE_LABEL,
        data: [
          ...heatmap_columns.map((colName, colIndex) => {
            return {
              x: colName,
              y: Math.round(heatmap_column_means[colIndex] * 100),
            };
          }),
          {
            x: AVERAGE_LABEL,
            y: Math.round(
              ([...heatmap_column_means, ...heatmap_row_means].reduce((sum, val) => sum + val, 0) /
                [...heatmap_column_means, ...heatmap_row_means].length) *
                100
            ),
          },
        ],
      },
    ];

    // Calculates for row average (Adds new column average)
    const seriesWithRowMeans = series.map((serie, serieIndex) => {
      const {name, data} = serie;
      return {
        name,
        data: [...data, {x: AVERAGE_LABEL, y: Math.round(heatmap_row_means[serieIndex] * 100)}],
      };
    });

    const newSeries = [...columnMeanSeries, ...seriesWithRowMeans];
    void refreshChart(newSeries);
    return newSeries;
  }, [heatmapData]);

  useEffect(() => {
    void refreshChart(series);
  }, [showLicensesPanel]);

  return (
    <div>
      <div className="tr-flex tr-p-4">
        <CustomLegend />
        <div className="tr-flex tr-gap-4 tr-ml-auto tr-items-center tr-w-full tr-max-w-[50%]">
          <label className="tr-mb-0">Y Value</label>
          <Select
            name="y-value"
            defaultValue={selectedYValue}
            value={selectedYValue}
            options={OPTIONS.filter((o) => o.value !== selectedXValue.value)}
            onChange={(e: any) => {
              setSelectedYValue(e);
            }}
            required
          />
          <label className="tr-mb-0">X Value</label>
          <Select
            name="x-value"
            defaultValue={selectedXValue}
            value={selectedXValue}
            options={OPTIONS.filter((o) => o.value !== selectedYValue.value)}
            onChange={(e: any) => {
              setSelectedXValue(e);
            }}
            required
          />
        </div>
      </div>

      {!heatmapData || heatmapData?.data?.length === 0 ? (
        <NoData>{loading ? 'Data is loading...' : 'No data for the current selection'}</NoData>
      ) : (
        <div>
          <Chart options={HEAT_MAP_OPTIONS} series={series} type="heatmap" height={400} />
        </div>
      )}
    </div>
  );
}
